import React from "react"
import { Link } from "gatsby"

import {
  MdDirectionsBike,
  MdOutlineSkateboarding,
  MdOutlineKayaking,
} from "react-icons/md"
import { FaHiking, FaSkiingNordic } from "react-icons/fa"
import { GiMountainClimbing } from "react-icons/gi"
import { Layout, Seo, PageSection, randomImg, classes } from "../layout"
import {
  FindOutMoreButton,
  AvailableAsWebApp,
  IPhone,
  IPhoneCarousel,
  img,
} from "../components"

export default function IndexPage(props) {
  const {
    sectionText,
    statement,
    heading,
    paragraph,
    iphone,
    imageRight,
    imageLeft,
  } = classes
  const icon = "w-6 h-6 sm:w-8 sm:h-8 mr-5 sm:mr-8"
  const under_heading = "font-light -mt-4"

  return (
    <Layout header="light" path={props.path}>
      <Seo title="Home" />

      <div
        className="relative w-full min-h-screen tg-front-image"
        style={{
          backgroundImage: `url("${randomImg()}")`,
        }}
      >
        <div className="w-full flex max-w-5xl mx-auto justify-around pt-32 pb-8 sm:pb-16 md:px-4 lg:px-8">
          <div className="text-white">
            <div className="flex px-4">
              <MdDirectionsBike className={icon} />
              <FaHiking className={icon} />
              <FaSkiingNordic className={icon} />
              <MdOutlineSkateboarding className={icon} />
              <MdOutlineKayaking className={icon} />
              <GiMountainClimbing className={icon} />
            </div>
            <h1 className="px-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left">
              Digitalizing the outdoors
            </h1>
            <div className="text-lg p-4 sm:text-2xl sm:leading-10 mb-12 max-w-xl">
              Trailguide develops software and technology to help the tourism
              sector digitalize outdoor activities and make them available for
              guests and visitors
            </div>

            <FindOutMoreButton
              className="mx-4 shadow-md sm:border-2 border-green-100"
              to="#who"
            >
              Read more
            </FindOutMoreButton>
          </div>

          <div className="hidden md:block">
            <Carousel color="white" />
          </div>
        </div>
      </div>

      <div className="md:hidden flex justify-center py-12">
        <Carousel color="black" />
      </div>

      <PageSection id="who">
        <div className={sectionText}>
          <div className="max-w-3xl mx-auto">
            <p className={paragraph}>
              At Trailguide, we believe that technology can enhance and enrich
              the outdoor experience. That's why we develop innovative software
              and technology solutions specifically designed for the tourism
              industry.
            </p>
            <p className={paragraph}>
              Our goal is to help you digitalize your outdoor activities and
              make them accessible and convenient for your guests and visitors.
            </p>
            <p className={paragraph}>
              With Trailguide, you can offer your guests an enhanced and
              interactive outdoor experience, all while streamlining your
              operations and maximizing efficiency.
            </p>
            <p className={paragraph}>
              Let us help you take your outdoor offerings to the next level with
              our cutting-edge technology.
            </p>
          </div>
        </div>
      </PageSection>

      <PageSection id="app">
        <h1 className={heading}>Immediate access to tours and navigation</h1>
        <h4 className={under_heading}>
          The app opens in 1 second by scanning a QR code
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Trailguide is the ultimate tool for find outdoor activities and
              and navigating them with ease.
            </p>
            <p className={paragraph}>
              The app offers a wide range of features, including digital maps,
              tour descriptions, reviews, condition reports, and live GPS
              navigation.
            </p>
            <p className={paragraph}>
              As a tourist destination or resort you can make use of the app and
              its existing content or you can create your own custom content and
              make it available for your guests and visitors.
            </p>
            <p className={paragraph}>
              The best part? There's no need for installation or user
              registration for your guests - just start exploring!
            </p>
            <div className="flex flex-wrap items-start my-12">
              <Link href="https://trailguide.net" target="trailguide">
                <AvailableAsWebApp label="trailguide.net" />
              </Link>
              <FindOutMoreButton
                className="mt-4 md:mt-0 md:ml-4 whitespace-no-wrap md:h-14"
                to="/products/app"
              >
                Find out more
              </FindOutMoreButton>
            </div>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-world-4.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <PageSection id="sandbox">
        <h1 className={heading}>Have your own tour app</h1>
        <h4 className={under_heading}>
          With full control over all your tour content
        </h4>
        <div className={sectionText}>
          <div>
            <img
              alt="Trysil bike park app icon"
              className="rounded shadow w-24 float-left mr-8 mb-4"
              src={img("website/trailguide-sandbox-icon-trysil-bike-park.jpg")}
            />
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              Have total control over the content you serve your guests with
              your own custom tour app built on our proven technology plattform.
            </p>
            <p className={paragraph}>
              The amount of information presented to your guests is limited to
              those activities related to your destination only.
            </p>
            <p className={paragraph}>
              The app is a modern web app making it easy to get the proper
              information into the hands of your guests. The only thing they
              need to do is to scan a QR code or click a link.
            </p>
            <p className={paragraph}>
              Even though there is no installation required to use the app, the
              guests can install it in seconds by adding it to the home screen.
              The app will have the branding of the destination with the proper
              icon and name.
            </p>
            <FindOutMoreButton
              className="mr-4 whitespace-no-wrap"
              to="/products/whitelabel"
            >
              Find out more
            </FindOutMoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-sandbox.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <div id="statement" className={statement}>
        <div className="italic">
          <span className="text-2xl text-gray-700 pr-2">"</span>
          We have a goal that it should be easy to be a guest in Trysil.
          Trailguide has functionality that makes it easier for everyone to find
          biking and hiking trails at our destination. It's also great that you
          don't need to download and install an app in advance or register a
          profile. The solution is integrated on trysil.com and in our village.
          Guests can simply scan a QR code to get the relevant information,
          maps, and navigation tools.
          <span className="absolute text-2xl text-gray-700 pl-2">"</span>
        </div>
        <p className="ml-12 mt-4">- Olve Norderhaug, Destinasjon Trysil</p>
      </div>

      <PageSection id="presence">
        <h1 className={heading}>Make sure you are visible</h1>
        <h4 className={under_heading}>
          Ensure your presence in the Trailguide app
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Are you looking to increase visibility and attract more guests to
              your destination, resort, hotel, campsite, shop, or café?
              Trailguide can help!
            </p>
            <p className={paragraph}>
              By adding your destination or business to the our app, you make
              sure that outdoor enthusiasts can easily find you while planning
              their holidays or exploring your region.
            </p>
            <p className={paragraph}>
              Our app is used by thousands of people looking for places to
              sleep, eat, drink, shop, and more while enjoying the great
              outdoors.
            </p>
            <p className={paragraph}>
              By making sure you're visible on Trailguide, you'll be able to
              reach a whole new audience of potential guests and keep them in
              the region longer.
            </p>
            <p className={paragraph}>
              So don't miss out - add your business to Trailguide today and
              start seeing the benefits!
            </p>
            <FindOutMoreButton className="mt-8" to="/products/presence">
              Find out more
            </FindOutMoreButton>
          </div>
          <div className="hidden sm:block">
            <IPhone
              alt="Ensure your presence on Trailguide"
              src={img("/website/trailguide-iphone-presence2.png")}
              className={iphone}
            />
          </div>
        </div>
      </PageSection>

      <PageSection id="integration">
        <h1 className={heading}>Show off your potential early</h1>
        <h4 className={under_heading}>
          Integrate Trailguide content on your web site
        </h4>
        <div className="my-6 md:my-12 sm:flex items-start">
          <div>
            <p className={paragraph}>
              As a tourist destination or resort, you want to make sure that
              your visitors have access to all of the great outdoor activities
              that you have to offer.
            </p>
            <p className={paragraph}>
              With Trailguide, you can easily integrate all of your activity
              content directly onto your website, eliminating the need to update
              multiple platforms.
            </p>
            <p className={paragraph}>
              Our integration module is easy to add, lightning fast, and can be
              customized to fit seamlessly with your website.
            </p>
            <p className={paragraph}>
              Your entire team can share access and manage your content to
              ensure that it is always up-to-date and accurate.
            </p>
            <p className={paragraph}>
              Don't let your visitors miss out on all that you have to offer -
              use Trailguide to showcase your outdoor activities and give your
              guests the best possible experience.
            </p>
            <p className={paragraph}>
              <FindOutMoreButton className="mt-8" to="/products/plugin">
                Find out more
              </FindOutMoreButton>
            </p>
          </div>
          <div className="hidden sm:block ml-8 mt-8">
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 mb-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
 license="your_license_key"
 content="8064,6985,6816,8173"
 mode="carousel">
</trailguide-plugin>
`.trim()}
            </pre>
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
   license="your_license_key"
   content="7783,8064"
   lang="no"
   mode="full">
</trailguide-plugin>
  `.trim()}
            </pre>
          </div>
        </div>
      </PageSection>

      <PageSection id="landing-page">
        <h1 className={heading}>
          It should be easy to discover your best tours
        </h1>
        <h4 className={under_heading}>
          Have your own landing page on Trailguide
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              At Trailguide, we understand that it can be overwhelming for
              guests and visitors to navigate through all of the different tours
              and activities available at your destination.
            </p>
            <p className={paragraph}>
              That's why we offer the option to create a customized landing page
              in our app, where you can showcase all of your best tours and
              activities in one convenient place.
            </p>
            <p className={paragraph}>
              Your landing page is easily accessible from the Trailguide map, or
              guests can simply scan a QR code displayed in your lobby or
              reception area to access it.
            </p>
            <p className={paragraph}>
              With your own landing page on Trailguide, you can provide your
              guests with a digital tour guide that highlights all of your top
              offerings.
            </p>
            <p className={paragraph}>
              Make it easy for your guests to discover and choose the activities
              that suit them best - create your landing page on Trailguide
              today!
            </p>
            <p className={paragraph}>
              <FindOutMoreButton className="mt-8" to="/products/landingpage">
                Find out more
              </FindOutMoreButton>
            </p>
          </div>
          <IPhone
            alt="Your own landing page on Trailguide"
            src={img("/website/trailguide-iphone-landingpage-flims.jpeg")}
            className={iphone}
          />
        </div>
      </PageSection>

      <PageSection id="qr-codes">
        <h1 className={heading}>
          Guests should always have access to information
        </h1>
        <h4 className={under_heading}>
          Connect your physical environment to Trailguide
        </h4>
        <div className={sectionText}>
          <div className="pt-2">
            <p className={paragraph}>
              Welcome your guests and inspire them to explore your region with a
              stunning 3D-like topographical map from Trailguide.
            </p>
            <p className={paragraph}>
              Each map is custom made for your specific area and printed in
              incredibly fine detail.
            </p>
            <p className={paragraph}>
              Your guests can scan the included QR code to instantly access your
              customized landing page on Trailguide.
            </p>
          </div>
          <img
            className={imageRight}
            alt="Scan the QR code to open the digital tour guide"
            src={img("/website/trailguide-scan-map.jpg")}
          />
        </div>

        <div className={sectionText}>
          <img
            className={imageLeft}
            alt="Display QR codes where your guests first meet you"
            src={img("/website/trailguide-qr-display.jpg")}
          />
          <div className="pt-2">
            <p className={paragraph}>
              We truly believe in providing the best possible experience for
              your guests.
            </p>
            <p className={paragraph}>
              To make it even more convenient for your guests, display QR codes
              in prominent places, such as in your lobby or on your reception
              desk.
            </p>
            <p className={paragraph}>
              Guests will have access to digital information about your outdoor
              offerings the moment they arrive at your place.
            </p>
          </div>
        </div>
      </PageSection>

      <div id="statement2" className={statement}>
        <div className="italic">
          <span className="text-2xl text-gray-700 pr-2">"</span>
          The QR-codes are easy to use and since all our tours are on Trailguide
          they are immediately accessible for our visitors. Trailguide is an
          excellent platform for our staff working in the tourist office. We do
          not need to print all the brochures we used and since everything is
          digitalized we can ensure the information is always up to date for our
          guests. Trailguide makes our day-to-day operation as a tourist office
          a lot easier
          <span className="absolute text-2xl text-gray-700 pl-2">"</span>
        </div>
        <p className="ml-12 mt-4">- Mari Clementine Østgaard, Visit Ål</p>
      </div>

      <PageSection id="no-installation">
        <h1 className={heading}>Your guests should not need to do any work</h1>
        <h4 className={under_heading}>
          Immediate access with no installation or user registration
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              At Trailguide, we understand that the guest experience is of great
              importance.
            </p>
            <p className={paragraph}>
              That's why we've designed our products with your guests in mind,
              ensuring that they have easy, hassle-free access to all of the
              information they need.
            </p>
            <p className={paragraph}>
              We believe that your guests shouldn't have to jump through hoops
              or pay for access to tour information, digital maps, and GPS
              navigation.
            </p>
          </div>
          <img
            className={imageRight}
            alt="Instant access, no installation, registration, or cost"
            src={img("/website/trailguide-qr-display2.jpg")}
          />
        </div>
        <p className={paragraph}>
          We offer a solution that requires no installation, no user
          registration, or cost for your guests. All they have to do is scan a
          QR code using their phone, and they'll instantly have access to
          everything they need to explore and enjoy your region.
        </p>
        <p className={paragraph}>
          With Trailguide, you can provide your guests with a seamless,
          enjoyable experience that will keep them coming back for more.
        </p>
        <p className={paragraph}>
          Make it easy for your guests to discover all that your area has to
          offer with Trailguide.
        </p>
      </PageSection>
    </Layout>
  )
}

function Carousel(props = {}) {
  const { color, ...rest } = props
  return (
    <IPhoneCarousel
      color={color || "black"}
      images={[
        {
          label: "Trailguide has great outdoor activities all over the world",
          src: img("/website/trailguide-iphone-world-4.jpeg"),
        },
        {
          label:
            "Ensure the presence of your tourist destination on Trailguide",
          src: img("/website/trailguide-iphone-presence.jpeg"),
        },
        {
          label: "Have your own landing page with your own tours on Trailguide",
          src: img("/website/trailguide-iphone-landingpage.jpeg"),
        },
        {
          label: "Easy access to activities for your guests and visitors",
          src: img("/website/trailguide-iphone-landingpage2.jpeg"),
        },
        {
          label: "Detailed tour information, great maps, and clear tracks",
          src: img("/website/trailguide-iphone-map-detail.jpeg"),
        },
        {
          label: "Built-in navigation for your guests and visitors",
          src: img("/website/trailguide-iphone-navigation.png"),
        },
      ]}
      {...rest}
    />
  )
}
